.slider_listing > .MuiBox-root > .MuiSlider-root {
    color: #FFFFFF !important;
    height: 8px !important;
}
.slider_listing > .MuiBox-root > .MuiSlider-root > .MuiSlider-track {
    color: #01A7C4 !important;
    height: 8px !important;
}
.slider_listing{
    flex-direction: column !important;
    justify-content: center;
    width: 80% !important;
    margin-right: 3% !important;
}
.checkBoxGrid > .MuiCheckbox-root > .MuiSvgIcon-root {
    color: white !important;
}
.listingAuto{
    display: flex;
    justify-content: center;
    margin-top: 5px !important;
    width: 100%;
}
.filter_combo{
    width: 100%;
    max-height: 35px;
}
#combo_filter{
    color: black !important;
    font-family: 'shabnam' !important;
    font-size: 12px !important;
    margin-right: 5px!important;
}
.filter_button{
    background-image: linear-gradient(90deg, rgba(54,43,238,1) 0%, rgba(17,162,224,1) 60%) !important;
}
.filter_button  > .MuiSvgIcon-root{
    color: white !important;
}
.filter_button_close > .MuiSvgIcon-root{
    color: white !important;
}
.side_filter::-webkit-scrollbar{
    display: none;
}
.side_filter{
    -ms-overflow-style: none; 
    scrollbar-width: none;
}
.listingAuto > .MuiAutocomplete-root > .MuiFormControl-root > .MuiInputLabel-root{
    margin-top: -10px !important;
}
@media screen and (max-width:900px) {
    .listingAuto > .MuiAutocomplete-root{
        width: 90%;
    }
    .slider_listing{
        margin: 0 !important;
        padding-left: 2% !important;
        padding-right: 3% !important;
    }
    .listingAuto{
        margin-top: 1% !important;
    }
}