.slick-next {
    right: 25px;
}
.slick-prev {
    left: 25px;
    z-index: 1;
}
.slick-prev, .slick-next{
    width: 35px;
    height: 35px;
}
.slick-prev:before, .slick-next:before{
    font-size: 35px;
}
.slick-list {
    margin: -1px !important;
}
#report-list{
    font-family: 'shabnam' !important;
}