.text_Field:lang(fa) label {
  transform-origin: top right;
  left: auto;
  right: 30px;
  color :#7C7C7C !important;
  direction: rtl;
}

.text_field fieldset legend {
  text-align: center;
}

