.employeeAuto{
    display: flex !important;
    justify-content: center !important;
    margin-top: 10px !important;
}
.combo_employee{
    width: 45%;
    max-height: 35px !important;
}

