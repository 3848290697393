.vipOne {
    background-size: cover;
    background-position: center;
    min-width: 100vw;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.gridItemVip1{
    padding-right: 5%;
    padding-left: 5%;
}
.titleGrid {
    background-image: linear-gradient(to left, #FFFFFFFF ,  rgba(211, 211, 211, 0.9), #FFFFFF00);
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    padding: 20px;
    border-radius: 0 30px 8px 0;
    text-decoration: none;
    color: black;
}
.searchGrid{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(358.12deg, rgb(180, 238, 248) 1.65%, rgb(255, 255, 255) 97.45%);
    flex-wrap: wrap;
    border-bottom-left-radius: 25px;
    padding-top: 20px !important;
}
/*AutoComplete*/
.autoCompleteGrid{
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
}
.combo {
    width: 225px;
    max-height: 35px;
}
.textField {
    border-style: solid !important;
    border-width:  3px !important;
    border-color: #9ADCFF !important;
    border-radius: 10px !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.17);
    background-color: white;
    max-height: 35px;
}
#state_combo{
    color: black !important;
    font-family: 'shabnam' !important;
    font-size: 15px !important;
    padding-right: 15px !important;

}
.MuiAutocomplete-endAdornment{
    position: static !important;
}
.MuiSvgIcon-root {
    color: #01A7C4 !important;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.MuiAutocomplete-root .MuiOutlinedInput-root{
}
.MuiChip-label{
    font-family: 'shabnam';
}
.MuiAutocomplete-root .MuiAutocomplete-tag{
    background-color: white;
}
.MuiOutlinedInput-notchedOutline{
    border: none !important;
}
.MuiOutlinedInput-root{
    padding: 0px !important;
    overflow: hidden !important;
}
.MuiAutocomplete-listbox .MuiAutocomplete-option {
    color: #01A7C4 !important;
    font-family: 'shabnam' ;
    font-size: 15px !important;
}
/**/
.sliderGrid{
    display: flex ;
    flex-direction: column !important;
    align-items: center;
    margin-top: 20px !important;
}
.bedRoomButton{
    background-color: white !important;
    width: 85%;
}
.MuiSlider-root{
    color: #01C7E9 !important;
    height: 8px !important;
}
.MuiSlider-thumb{
    width: 17px !important;
    height: 17px !important;
}
.MuiSlider-valueLabel{
    background-color: #01A7C4 !important;
    font-family: 'shabnam' !important;
}
.sliderText{
    width: 215px;
}
.GridSearch{
    margin-top: -12vh !important;
}


@media (min-width:1536px) and (max-width:2000px){
    @media (min-width:1536px) and (max-width:1700px){
        .vip2-hover-top{
        background-position:-590px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:590px 0px !important ;
        }
    }
    @media (min-width:1700px) and (max-width:2000px){
        .vip2-hover-top{
        background-position:-675px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:675px 0px !important ;
        }
    }
    @media (min-width:1900px) and (max-width:2000px){
        .vip2-hover-top{
        background-position:-750px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:750px 0px !important ;
        }
    }
}

@media (min-width:1200px) and (max-width:1536px) {
    .combo{
        width: 220px;
    }
    .GridSearch{
        margin-top: -15vh !important;
    }
    @media (min-width:1200px) and (max-width:1350px){
        .vip2-hover-top{
        background-position:-475px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:475px 0px !important ;
        }
    }
    @media (min-width:1350px) and (max-width:1536px){
        .vip2-hover-top{
        background-position:-530px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:530px 0px !important ;
        }
    }
}

@media (min-width:900px) and (max-width:1200px) {
    .combo{
        width: 210px;
    }
    .GridSearch{
        margin-top: -15vh !important;
    }
    @media (min-width:900px) and (max-width:1000px){
        .vip2-hover-top{
        background-position:-350px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:350px 0px !important ;
        }
        
    }
    @media (min-width:1000px) and (max-width:1200px){
        .vip2-hover-top{
        background-position:-390px 0px !important ;
        }
        .vip2-hover-bottom{
        background-position:390px 0px !important ;
        }
    }
}

@media (min-width:600px) and (max-width:899px) {
    .GridSearch{
        margin-top: -15vh !important;
    }
    .searchGrid{
        justify-content: center !important;
    }
    .vip2-img{
        height: 400px !important;
    }
    @media (min-width:800px) and (max-width:899px) {
        .combo{
            width: 295px;
        }
    }
    @media (min-width:700px) and (max-width:799px) {
        .combo{
            width: 250px;
        }
        .sliderBox{
            width: 260px !important;
        }
    }
    @media (min-width:600px) and (max-width:699px) {
        .combo{
            width: 235px;
        }
        .sliderBox{
            width: 220px !important;
        }
    }
    @media (max-height:425px){
        .GridSearch{
            margin-top: -8vh !important;
        }
    }
}

@media (min-width:320px) and (max-width:599px) {
    .MuiSlider-root{
        height: 3px !important;
    }
    .MuiSlider-thumb{
        width: 10px !important;
        height: 10px !important;
    }
    .searchGrid{
        justify-content: space-around !important;
    }
    .MuiInputLabel-root{
        font-size: 12px !important;
    }
    .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input{
        font-size: 10px !important;
    }
    .searchButton{
        height: 45px !important;
        width: 55%;
    }
    .GridSearch{
        margin-top: -15vh !important;
    }
    @media (min-width:320px) and (max-width:399px) {
        .combo{
            width: 115px;
        }
        .sliderText{
            width: 90%;
        }
        .searchGrid{
            padding: 5px !important;
        }
        .searchButton{
            margin-left: -5px !important;
            margin-bottom: -5px !important;
        }
    }
    @media (min-width:400px) and (max-width:499px) {
        .combo{
            width: 150px;
        }
        .sliderBox{
            width: 85% !important;
        }
        .sliderText{
            width: 85%;
        }
    }
    @media (min-width:500px) and (max-width:599px) {
        .combo{
            width: 195px;
        }
        .sliderText{
            width: 88%;
        }
        .vip2-img{
            height: 300px !important;
        }
    }
    @media (max-height:425px){
        .GridSearch{
            margin-top: -8vh !important;
        }
    }
}

.vipClickable:hover {
    cursor: pointer;
}
.clearFix::after {
    content: "";
    clear: both;
    display: table;
}
.vip2-hover-icon{
    transition: all .5s;
}
.vip2-hover-top:hover .vip2-hover-icon{
    background-color: white !important;
}
.vip2-hover-bottom:hover .vip2-hover-icon{
    background-color: white !important;
}
.vip2-hover-top:hover{
    background-position:0px 0px !important ;
}
.vip2-hover-bottom:hover{
    background-position:0px 0px !important ;
}
.ads-autocomplete {
    border: 2px solid #eee;
    border-radius: 6px;
    padding: 12px 5px;
}
#ads-autocomplete-input{
    font-family: "shabnam";
}
#ads-autocomplete-input::placeholder{
    color: black !important;
    opacity: 1;
}
.ads-textfield {
    border: 2px solid #eee !important;
    border-radius: 6px !important;
    box-sizing: border-box;
}
#ads-textfield-input {
    font-size: 20px;
    font-family: "shabnam";
}
#ads-textfield-input::placeholder {
    font-size: 16px;
    opacity: 1;
    font-family: "shabnam";
}
.ads-textfield label {
    transform-origin: top right;
    left: auto;
    right: 22px;
    font-family: "shabnam";
    color:black;
}
.ads-textfield label {
    transform-origin: top right;
    left: auto;
    right: 22px;
    font-family: "shabnam";
    background-color: white;
    padding: 0 5px;
}
.ads-textfield label.Mui-focused {
    color: black;
    background-color: white;
    padding: 0 5px;
    font-family: "shabnam";
}
.primaryinfo-textarea:focus {
    border: none;
    outline: none;
}
@media (min-width:320px) and (max-width:900px){
    #ads-autocomplete-input{
        font-size: 15px !important;
    }
    .ads-textfield label {
        font-size: 15px !important;
    }
}

.MuiAutocomplete-noOptions {
    font-family: "shabnam" !important;
    font-size: 14px;
}