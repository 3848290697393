.zone_link {
  text-decoration: none;
  display: inline-block;
  margin: 5px 2px;
  border-radius: 8px;
  background-color: white;
}
.zone_link:hover {
  box-shadow: 0 1px 6px 0 #d7caca;
  transition: all 0.3s;
}
.zone_link .zone_link_text {
  padding: 5px 10px;
  font-family: "shabnam";
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
.zone_link .zone_link_icon {
  color: black !important;
  font-size: 18px;
  margin-right: 5px;
}
@media (min-width:0px) and (max-width:700px){
  .zone_link .zone_link_text {
    font-size: 12px;
  }
  .zone_link .zone_link_icon {
    font-size: 12px;
  }
}
.zone_text_link {
  text-decoration: none;
  color: #0F92A9 ;
}