.tehran_map_province {
  fill: #E6FFFF;
  fill-opacity: 1;
  stroke:white;
  stroke-opacity: 1;
  stroke-width:1.3;
  transition: all 0.5s;
}
.tehran_map_link:hover path {
  fill:#002A45 !important;
}
.tehran_map_link:hover text {
  fill:white !important;
}
.tehran_map_container {
  width: 100%;
  height: 100%;
  direction: ltr;
}
text{
  font-family: "shabnam";
}
          