.navbar{
    background-color:  #FFFFFF !important;
    height: 88px;
    padding-right: 2%;
    padding-left: 2%;
    justify-content: center;
}

.logo{
    height: 80px;
    width: 80px;
}

.navbarGridOrg{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.gridLink ,.gridImage{
    display: flex;
    align-items: center;
}

.gridLogo{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.gridButton{
    display: flex;
    align-items: center;
    justify-content:flex-end;
}

.gridLink{
    justify-content: space-evenly;
}

.linkNav{
    text-decoration: none;
    margin-left: 1%;
    color: black !important;
}

.dividerNav{
    background-color: white;
    height: 2px;
    margin-top: 2px !important;
    transform: scale(0);
    transition: transform 0.4s ease;
}

.linkNav:hover .dividerNav{
    transform: scale(1);
}

.textNav {
    font-size: 17px;
    font-weight: 400;
    text-align: center;
}
.textButton{
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    display: flex;
    align-items: center;
}

.textNav:hover{
    font-weight: bold;
}

.textNav:hover  .linkNav{
    text-decoration: underline;
}


.linkButtonLogin, .linkButtonRegister{
    width: 100px;
    height: 45px;
    background: #14C9E8;
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: #F5F5F5;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 8px 18px;
    transform: rotate(-0.37deg);
}

.linkButtonRegister{
    background-color: white;
}

.navbarGridOrgS{
    display: none;
}

.menuImg{
    height: 25px;
    width: 35px;
}
.close-img{
    height: 25px;
    width: 30px;
}
.menuOrg{
    display: flex;
    position: absolute;
    top: 85px;
    background-color: white;
    width: 100%;
    flex-direction: column !important;
    right: 0;
    overflow-y: scroll;
}

.menuRow{
    display: flex;
    width: 100%;
    height: 20%;
    align-items: center;
}

.rowList{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
}
.loginList{
    margin-left: 20px !important;
}
.linkButtonLoginList, .linkButtonRegisterList{
    text-decoration: none;
    width: 40%;
    border: 2px solid #01C7E9;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 8px;
    color: black;
}

.linkButtonRegisterList{
    background-color: #01C7E9;
}

.textList{
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

@media (max-width:899px) {
    .navbar{
        padding-right: 0;
    }
    .navbarGridOrg{
        display: none;
    }
    .navbarGridOrgS{
        display: flex;
    }
    .logo{
        height: 60px;
        width: 60px;
    }
    .navbar{
        height: 70px;
    }
    .menuOrg{
        top: 70px;
    }
    .iconImg{
        color:#FFFFFF !important;
    }
    .textList{
        font-size: 12px;
        font-weight: 600;
    }
    .gridLogo{
        justify-content: center;
    }
    @media (max-width:450px) {
        .menuImg{
            height: 20px;
            width: 30px;
        }
        .navbar{
            height: 60px;
        }
        .menuOrg{
            top: 60px;
        }
        .textList{
            font-size: 10px;
            font-weight: 600;
        }
        .logo{
            height: 50px;
            width: 50px;
        }
        .loginList{
            margin-left: 10px !important;
        }
        .gridLogo{
            justify-content:center;
        }
       
    }
}

@media (min-width:900px) and (max-width:1200px){
    .textNav , .textButton{
        font-size: 15px;
    }
}

.linkNav.active .dividerNav{
    transform: scale(1);
}
.activeNav{
    display: none;
}
.linkNav.active .activeNav{
   display:flex
}
.linkNav.active .textNav{
    font-size: 18px;
    color: #14C9E8;
}

.menuLink {
    font-family: shabnam;
    color: black;
    text-decoration: none;
    display: block;
    width: 100%;
}

.menuLink:hover .dividerNav{
    transform: scale(1);
}
