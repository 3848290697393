.ads-autocomplete {
    border: 2px solid #eee;
    border-radius: 6px;
    padding: 12px 5px;
}
#ads-autocomplete-input{
    font-family: "shabnam";
}
#ads-autocomplete-input::placeholder{
    color: black !important;
    opacity: 1;
}
.ads-textfield {
    border: 2px solid #eee !important;
    border-radius: 6px !important;
    box-sizing: border-box;
}
#ads-textfield-input {
    font-size: 20px;
    font-family: "shabnam";
}
#ads-textfield-input::placeholder {
    font-size: 16px;
    opacity: 1;
    font-family: "shabnam";
}
.ads-textfield label {
    transform-origin: top right;
    left: auto;
    right: 22px;
    font-family: "shabnam";
    color:black;
}
.ads-textfield label {
    transform-origin: top right;
    left: auto;
    right: 22px;
    font-family: "shabnam";
    background-color: white;
    padding: 0 5px;
}
.ads-textfield label.Mui-focused {
    color: black;
    background-color: white;
    padding: 0 5px;
    font-family: "shabnam";
}
.primaryinfo-textarea:focus {
    border: none;
    outline: none;
}
@media (min-width:320px) and (max-width:900px){
    #ads-autocomplete-input{
        font-size: 15px !important;
    }
    .ads-textfield label {
        font-size: 15px !important;
    }
}