.title {
  font-family: "shabnam" !important;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: normal !important;
  margin: 20px 0 !important;
}

.h2 {
  font-family: "shabnam" !important;
  font-weight: bold  !important;
  font-size: 20px !important;
  line-height: normal !important;
  margin: 20px 0 !important;
}

.h3 {
  font-family: "shabnam" !important;
  font-weight: bold !important;
  font-size: 18px !important;
  line-height: normal !important;
  margin: 15px 0 !important;
}

.h4 {
  font-family: "shabnam" !important;
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: normal !important;
  margin: 15px 0 !important;
}

.h5 {
  font-family: "shabnam" !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: normal !important;
  margin: 14px 0 !important;
}

.h6 {
  font-family: "shabnam" !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: normal !important;
  margin: 13px 0 !important;
}

.date_author {
  font-family: "shabnam" !important;
  font-size: 14px !important;
  color: #b7b7b7 !important;
}

.p {
  font-family: "shabnam" !important;
  font-size: 16px !important;
  margin: 10px 0 !important;
  text-align:justify;
  text-justify:inter-word;
}

.image_wrapper {
  display: block !important;
  width: 100% !important;
  margin: 25px auto;
}

.image {
  display: block !important;
  width: 100% !important;
  height: auto !important;
}

.image_caption {
  font-size: 13px;
  text-align: center;
  padding: 5px;
  color: gray;
  background-color: #eee;
}

.box{
  padding:20px;
  background-color: #E7F0F3;
  border-radius: 4px;
  border-right: 4px solid #14C9E8;
}

.link {
  text-decoration: none;
  color: #0F92A9 ;
}

.li{
  text-align:justify;
  text-justify:inter-word;
}