.accordGridbackup > .MuiPaper-root > .MuiAccordionSummary-root > .MuiAccordionSummary-expandIconWrapper > .MuiSvgIcon-root{
    color: black !important;
    font-size: xx-large;
}
.textSummer{
    color: black !important;
    font-family: 'shabnam' !important;
    font-weight: bold !important;
    font-size: 1.4em !important;
}
.textAction{
    color: black !important;
    font-family: 'shabnam' !important;
    font-weight: 700 !important;
    font-size: 1.1em !important;
    margin-right: 30px !important;
}
.accordaction{
    cursor: pointer;
    
}
.accordaction:hover{
    background-color: #218ea1;
}
.side_menu::-webkit-scrollbar{
    display: none;
}
.side_menu{
    -ms-overflow-style: none; 
    scrollbar-width: none;
}